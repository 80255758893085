<template>
  <div class="quick-contact" ref="quick_contact" :class="position + '-' + align">
    <div class="contact" :style="{'--cursor': disabled ? 'default' : phone || email ? 'pointer' : 'normal'}">
      <i class="ez-icon-email icon-email" v-if="showEmailBtn" @click="sendEmail"/>
      <i class="ez-icon-call icon-call" v-if="showCallBtn" @click="callCustomer"/>
      <i class="ez-icon-sms icon-sms" v-if="showSMSBtn && !disableSms" @click="sendSMS"/>
      <slot name="contact"/>
    </div>

    <div class="in-call" v-if="onCall">
      <slot name="in-call"/>
    </div>

  </div>
</template>

<script>
import {bus} from '@/helpers/bus';
import {companyPermissions} from "@/mixins/companyPermissions";

export default {
  name: "QuickContact",

  props: {
    phone: {type: String, default: null},
    onCall: {type: Object, default: null},
    email: {type: String, default: null},
    disabled: {type: Boolean, default: false},
    disableSms: {type: Boolean, default: false},
    position: {
      type: String,
      enum: ["top", "bottom"],
      default: "bottom"
    },
    align: {
      type: String,
      enum: ["start", "end"],
      default: "end"
    },
  },

  computed: {
    showSMSBtn() {
      if (this.hasChatPermissions) {
        if (this.disabled) return false;
        return this.phone !== null;
      }
      return false;
    },

    showCallBtn() {
      if (this.hasCallCenterPermissions) {
        if (this.disabled) return false;
        return this.phone !== null;
      }
      return false;
    },

    showEmailBtn() {
      if (this.hasWebMailPermissions) {
        if (this.disabled) return false;
        return this.email !== null
      }
      return false;
    },
  },

  methods: {
    sendSMS() {
      if (this.disabled) return
      if (this.disableSms) return
      if (this.showSMSBtn)
        bus.$emit('SHOW_SMS_MODAL', {to: this.phone});
    },

    callCustomer() {
      if (this.disabled) return
      if (this.showCallBtn) {
        this.$call({To: this.phone});
      }
    },

    sendEmail() {
      if (this.disabled) return

      if (!this.$getUser().MailConfig) {
        this.$router.push({name: 'Mail', query: {box: 'Settings'}});

      } else if (this.showEmailBtn) {
        bus.$emit('SHOW_EMAIL_COMPOSE', {to: this.email});
      }
    },
  },

  mixins: [companyPermissions],
};
</script>

<style scoped lang="scss">
.quick-contact {
  --cursor: pointer;
  position: relative;

  .contact {
    position: relative;
    display: flex;
    align-items: center;
    gap: .3rem;

    i {
      border-radius: 50%;
      padding: .25rem;
      font-size: .7rem;
    }

    .icon {
      &-email,
      &-sms,
      &-call {
        cursor: var(--cursor);
        display: flex;
        align-items: center;
      }

      &-email {
        background: rgba(color(bg-primary), .7);
        color: color(bg-white);
      }

      &-sms {
        background: color(bg-purple);
        color: color(bg-white);
      }

      &-call {
        background: color(bg-green);
        color: color(bg-white);
      }
    }
  }

  .in-call {
    background: color(bg-white);
    color: color(bg-green);
    position: relative;
    padding: .2rem 1.25rem;
    font-size: .7rem;
    margin-top: .2rem;

    &::before {
      content: '';
      background: color(bg-green);
      border-radius: 50%;
      width: 5px;
      height: 5px;
      position: absolute;
      left: .5rem;
      top: calc(50% - 2.5px);
      animation: pulse 2s infinite;
    }
  }

  .actions {
    background: #fff;
    border-radius: 3px;
    position: absolute;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    z-index: 999;

    .action {
      align-items: center;
      display: flex;
      justify-content: center;
      background: color(bg-primary-light);
      color: color(bg-primary);
      padding: 10px;
      min-width: 80px;

      i {
        font-size: 16px;
        color: color(bg-primary);
      }

      span {
        font-size: 14px;
        margin-left: 10px;
      }

      &:hover {
        background: color(bg-primary);
        color: color(bg-white);

        i {
          color: color(bg-white);
        }
      }
    }
  }

  &.bottom-start .actions {
    top: 25px;
    left: 0;

    &:before {
      left: 15px;
      top: 0;
    }

    &:after {
      top: -9px;
      left: 20px;
    }
  }

  &.bottom-end .actions {
    top: 25px;
    right: 0;

    &:before {
      right: 15px;
      top: 0;
    }

    &:after {
      top: -8px;
      right: 20px;
    }
  }
}


@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(color(bg-green), 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(color(bg-green), 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(color(bg-green), 0);
  }
}
</style>

<i18n scoped>
{
  "en": {
    "make_call": "Call",
    "send_sms": "SMS",
    "send_email": "Send email"
  },
  "es": {
    "make_call": "LLamar",
    "send_sms": "SMS",
    "send_email": "Enviar correo"
  }
}
</i18n>